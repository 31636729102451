<script>
	import Base from '@backend/Base.vue';

	import BOGen from '@helper/BOGen';
	import Gen from '@helper/Gen';

	export default {
		name: "BoContactSetting",
		extends: Base,
		data() {
			return {
				Name: "BoContactSetting",
				mrLevel: {},
				input: {},
				message_bellissimo: {},
				message_cape: {},
				message_package: {},
			}
		},
		mounted() {
			this.$set(this.$root, 'page', this)
			this.refreshData()
		},
		methods: {
			openAddContacts() {
				this.input = {}
				$("#add_address").modal()
			},
			showModalEditContact(id) {
				$("#edit_address").modal()
				BOGen.apirest("/" + this.Name, {
					id: id,
					type: 'showContacts'
				}, (err, resp) => {
					if (err) return swal(err.resp.message, '', 'warning')
					this.input = resp.data
				}, "POST")
			},
			addContact(e, callback) {
				if (e && e.btnLoading()) return;
				this.input.type = 'addContact'
				BOGen.apirest("/" + this.Name, this.input, (err, resp) => {
					if (e) e.btnUnloading()
					if (resp.error) return swal(resp.message, '', 'warning')
					$("#add_address").modal("hide")
					this.input = {}
					this.refreshData()
					swal(resp.message, '', 'success')
				}, "POST")
			},
			editContact(e, callback) {
				if (e && e.btnLoading()) return;
				this.input.type = 'editContact'
				BOGen.apirest("/" + this.Name, this.input, (err, resp) => {
					if (e) e.btnUnloading()
					if (resp.error) return swal(resp.message, '', 'warning')
					$("#edit_address").modal("hide")
					this.input = {}
					this.refreshData()
					swal(resp.message, '', 'success')
				}, "POST")
			},
			deleteContact(id, k) {
				swal({
					title: "Are you sure to delete this contact?",
					text: "",
					icon: "warning",
					buttons: ["No, Cancel!", "Yes, Sure!"],
					dangerMode: true,
				}).then((ok) => {
					if (ok) {
						BOGen.apirest("/" + this.Name, {
							type: "deleteContact",
							id: id
						}, () => {
							// callback delete item
						}, "POST")
						this.data.data.splice(k, 1);
						swal("Deleted!", "Contact has been deleted.", "success");
					}
				});
			},
			updateMessage(e, callback) {
				if (e && e.btnLoading()) return;
				let input = {}
				input.message_cape = this.message_cape
				input.message_bellissimo = this.message_bellissimo
				input.message_package = this.message_package
				input.type = 'update'
				BOGen.apirest("/" + this.Name, input, (err, resp) => {
					if (e) e.btnUnloading()
					if (resp.error == 1) return Gen.info(resp.message, resp.style, 3000, '.info')
					if (resp.success) {
						Gen.info(resp.message, resp.style, 3000, '.info')
						this.refreshData()
					}
				}, "POST")
			},
			regexPhone(text) {
				if (!text) return
				// var parseText = text.toString()
				var x = text.replace(/[^0-9]/g, '');
				this.$set(this.input, 'ac_contacts', x)
			},
		},
		watch: {
			'$route.query'() {
				this.refreshData()
			},
			'filter.level'() {
				this.search()
			},
		}
	}
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>

		<!-- ======================================================================== 
			LIST DATA
		============================================================================= -->
		<div class="col-12">
			<div class="card">
				<div class="card-body">
					<div class="d-flex justify-content-between align-items-center">
						<h5 class="card-title mb-0">Contacts</h5>
						<a href="javascript:;" @click="openAddContacts" class="btn btn-info"><i class="fa fa-plus-circle"></i> Add
							New</a>
					</div>
					<hr>
					<div class="row">
						<div class="col-md-3" v-for="(v,k) in data.data" :key="k">
							<div class="card border">
								<div class="card-body text-right">
									<h5 class="card-title">{{v.ac_contacts}}</h5>
									<h6 class="card-subtitle">{{v.ac_type == 'E' ? 'Email' : 'WhatsApp'}}</h6>
									<p class="mb-0 float-left">
										<span class="badge badge-success" v-if="v.ac_status == 'Y'">Active</span>
										<span class="badge badge-danger" v-else>Inactive</span>
									</p>
									<p class="mb-0">
										<a href="javascript:;" v-if="page.moduleRole('Remove')" @click="deleteContact(v.id,k)"
											class="text-inverse icon_action"><i class="ti-trash"></i></a>
										<a href="javascript:;" v-if="page.moduleRole('Update')" @click="showModalEditContact(v.id)"
											class="text-inverse icon_action"><i class="ti-pencil"></i></a>
									</p>
								</div>
							</div>
						</div>
					</div>
					<div class="panel-body">
						<div class="pull-right">
							<Pagination :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-12">
			<VForm @resp="updateMessage" action="post">
				<div class="card">
					<div class="card-body">
						<div class="d-flex justify-content-between align-items-center">
							<h5 class="card-title">Message</h5>
						</div>
						<hr>
						<div class="row">
							<div class="col-md-12 info"></div>
							<div class="col-md-6">
								<div class="form-group">
									<div class="card border">
										<div class="card-body">
											<h5 class="card-title">Cape Discovery</h5>
											<hr>
											<BoField name="ac_subscribe_phone" :attr="{type:'Number'}">
												<div class="input-group mb-n4">
													<div class="input-group-prepend">
														<span class="input-group-text">+62</span>
													</div>
													<input type="number" class="form-control" v-model="message_cape.sm_phone"
														placeholder='0856xxxxxxxx'>
												</div>
											</BoField>
											<BoField name="ac_subscribe_message" class="mb-2">
												<textarea v-model="message_cape.sm_message" :placeholder="fields.ac_subscribe_message"
													class="form-control"></textarea>
											</BoField>
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group">
									<div class="card border">
										<div class="card-body">
											<h5 class="card-title">Bellissimo</h5>
											<hr>
											<BoField name="ac_subscribe_phone" :attr="{type:'Number'}" placeholder='62856xxxxxxxx'>
												<div class="input-group mb-n4">
													<div class="input-group-prepend">
														<span class="input-group-text">+62</span>
													</div>
													<input type="number" class="form-control" v-model="message_bellissimo.sm_phone"
														placeholder='0856xxxxxxxx'>
												</div>
											</BoField>
											<BoField name="ac_subscribe_message" class="mb-2">
												<textarea v-model="message_bellissimo.sm_message" :placeholder="fields.ac_subscribe_message"
													class="form-control"></textarea>
											</BoField>
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group">
									<div class="card border">
										<div class="card-body">
											<h5 class="card-title">Package</h5>
											<hr>
											<BoField name="ac_subscribe_phone" :attr="{type:'Number'}" placeholder='62856xxxxxxxx'>
												<div class="input-group mb-n4">
													<div class="input-group-prepend">
														<span class="input-group-text"> +62</span>
													</div>
													<input type="number" class="form-control" v-model="message_package.sm_phone"
														placeholder='0856xxxxxxxx'>
												</div>
											</BoField>
											<BoField name="ac_subscribe_message" class="mb-2">
												<textarea v-model="message_package.sm_message" :placeholder="fields.ac_subscribe_message"
													class="form-control"></textarea>
											</BoField>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12">
						<div class="card">
							<div class="card-body text-right">
								<button type="submit" v-if="page.moduleRole('Update')" class="btn btn-loading btn-success">Submit Changes</button>
							</div>
						</div>
					</div>
				</div>
			</VForm>
		</div>

		<!-- ======================================================================== 
			FORM CRUD	
		============================================================================= -->
		<div class="card" v-if="$route.params.id">
			<VForm @resp="submitForm" method="post">
				<div class="card-body">
					<div class="row mb-3">
						<div class="col-md-8">
							<h5 class="card-title">{{(row.id?"Edit":"Add")+" "+ObjectName}}</h5>
						</div>
					</div>
					<div class="info"></div>
					<div class="row">
						<div class="col-sm-12">
							<BoField name="ss_page" v-model="row.ss_page" :attr="{readonly:'readonly'}"></BoField>
							<BoField name="ss_title_id" v-model="row.ss_title_id"></BoField>
							<BoField name="ss_title_en" v-model="row.ss_title_en"></BoField>
							<BoField name="ss_keyword_id">
								<TagsInput name="ss_keyword_id" v-model="row.ss_keyword_id" :attr="validation('ss_keyword_id')">
								</TagsInput>
							</BoField>
							<BoField name="ss_keyword_en">
								<TagsInput name="ss_keyword_en" v-model="row.ss_keyword_en" :attr="validation('ss_keyword_en')">
								</TagsInput>
							</BoField>
							<BoField name="ss_desc_id">
								<textarea v-model="row.ss_desc_id" rows="4" class="form-control"></textarea>
							</BoField>
							<BoField name="ss_desc_en">
								<textarea v-model="row.ss_desc_en" rows="4" class="form-control"></textarea>
							</BoField>
						</div>
						<div class="col-xs-12">
							<div class="text-right">
								<button type="submit" class="fcbtn btn btn-info btn-outline btn-1e btn-loading">Submit form <i
										class="icon-arrow-right14 position-right"></i></button>
							</div>
						</div>
					</div>
				</div>
			</VForm>
		</div>
		<div id="edit_address" class="modal" tabindex="-1" role="dialog" aria-labelledby="add_team" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title" id="prod_cat">Edit Contact</h4>
						<button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
					</div>
					<VForm @resp="editContact" method="post">
						<div class="modal-body">
							<div class="row">
								<div class="col-12">
									<div class="form-group">
										<label for="">{{fields.ac_type}}</label>
										<select name="" id="" v-model="input.ac_type" class="form-control custom-select">
											<option value="W" selected>WhatsApp</option>
											<option value="E">Email</option>
										</select>
									</div>
								</div>
								<div class="col-12">
									<div class="form-group">
										<label for="">{{fields.ac_contacts}}</label>
										<input v-if="input.ac_type=='E'" type="text" v-model="input.ac_contacts" name="" id=""
											class="form-control">
										<div v-else class="input-group">
											<div class="input-group-prepend">
												<span class="input-group-text">+62</span>
											</div>
											<input type="text" @change="regexPhone(input.ac_contacts)" v-model="input.ac_contacts" name=""
												id="" class="form-control">
										</div>
									</div>
								</div>
								<div class="col-12">
									<div class="form-group">
										<Checkbox class="mt-4" v-model="input.ac_status">Active</Checkbox>
									</div>
								</div>
							</div>
						</div>
						<div class="modal-footer">
							<button type="submit" class="btn btn-rounded btn-loading btn-info">Submit</button>
						</div>
					</VForm>
				</div>
			</div>
		</div>
		<div id="add_address" class="modal" tabindex="-1" role="dialog" aria-labelledby="add_team" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title" id="prod_cat">Add Contact</h4>
						<button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
					</div>
					<VForm @resp="addContact" method="post">
						<div class="modal-body">
							<div class="row">
								<div class="col-12">
									<div class="form-group">
										<label for="">{{fields.ac_type}}</label>
										<select name="" id="" v-model="input.ac_type" class="form-control custom-select">
											<option value="W" selected>WhatsApp</option>
											<option value="E">Email</option>
										</select>
									</div>
								</div>
								<div class="col-12">
									<div class="form-group">
										<label for="">{{fields.ac_contacts}}</label>
										<input v-if="input.ac_type=='E'" type="text" v-model="input.ac_contacts" name="" id=""
											class="form-control">
										<div v-else class="input-group">
											<div class="input-group-prepend">
												<span class="input-group-text">+62</span>
											</div>
											<input type="text" @change="regexPhone(input.ac_contacts)" v-model="input.ac_contacts" name=""
												id="" class="form-control">
										</div>
									</div>
								</div>
								<div class="col-12">
									<div class="form-group">
										<Checkbox class="mt-4" v-model="input.ac_status">Active</Checkbox>
									</div>
								</div>
							</div>
						</div>
						<div class="modal-footer">
							<button type="submit" class="btn btn-rounded btn-loading btn-info">Submit</button>
						</div>
					</VForm>
				</div>
			</div>
		</div>
	</div>
</template>